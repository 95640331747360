import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {SaLocksComponent} from "./saLocks.component";
import {SaCabinetDetailComponent} from "../cabinets/saCabinet-detail.component";
import {MatLegacySelectChange as MatSelectChange} from "@angular/material/legacy-select";

@Component({
  selector: 'micro-saLock-detail',
  templateUrl: './saLock-detail.component.html'
})
export class SaLockDetailComponent implements OnInit {

  @Input()
  lockId:any;

  saLock:any;
  disableSerial:boolean;
  lockDefaultParamSets:any = {};
  lockDefaultParamSetOptions:any[] = [];
  lockDefaultParamSetSelected;

  @ViewChild(SaCabinetDetailComponent, {static: false}) saCabinetDetailComponent: SaCabinetDetailComponent;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.lockId) {
      this.loadId(this.lockId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.loadId(id);
          }
        });
    }

    this.http.get(`${this.env.e.url}/access/saLocks/lockDefaultParamSets`).subscribe(
      data => {
        let paramSetList = data as any[];
        for (const paramSetListElement of paramSetList) {
          let set = this.lockDefaultParamSets[paramSetListElement.lockType];
          if (set === undefined) {
            set = [];
            this.lockDefaultParamSets[paramSetListElement.lockType] = set;
          }
          set.push(paramSetListElement);
        }
        this.setupParamSets();
      }
    );

  }

  disableSerialRule() {
    return (!!this.saLock.id && !!this.saLock.serialNumber) || !this.authService.hasRole('SITE_LOCK_COMPANY');
  }

  loadId(id) {
    this.http.get(`${this.env.e.url}/access/saLocks/${id}`).subscribe(
      data => {
        this.setSaLock(data);
      }
    );
  }

  setSaLock(saLock:any) {
    this.saLock = saLock;
    this.disableSerial = this.disableSerialRule();
    this.setupParamSets();
  }

  createNew() {
    this.saLock = {
      companyId: this.authService.getCompanyId(),
      status: 'REGISTERED',
      type: 'Pulse',
      validityPeriod: 2,
      lockParam: {
        paramType: "NONE"
      },
    };
    this.disableSerial = this.disableSerialRule();
  }

  save() {
    if (!this.saLock.id) {
      this.http.post(`${this.env.e.url}/access/saLocks`, this.saLock)
        .subscribe(
          data => {
            this.setSaLock(data);
            if (this.saCabinetDetailComponent) {
              this.saCabinetDetailComponent.reload();
            }
            this.alertService.info(`Created ${this.saLock.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/access/saLocks/${this.saLock.id}`, this.saLock)
        .subscribe(
          data => {
            this.setSaLock(data);
            if (this.saCabinetDetailComponent) {
              this.saCabinetDetailComponent.reload();
            }
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/access/saLocks/${this.saLock.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.saLock.name}`);
          this.router.navigate(["/saLocks"]);
        }
      );
  }

  typeChange() {
    switch (this.saLock.type) {
      case 'Servo':
        this.saLock.lockParam = {
          angle: undefined,
          angleMin: undefined,
          angleMax: undefined,
          pwmMin: undefined,
          pwmMax: undefined,
          paramType: 'SERVO',
        }
        break;
      case 'ServoDelayed':
        this.saLock.lockParam = {
          angle: undefined,
          angleMin: undefined,
          angleMax: undefined,
          pwmMin: undefined,
          pwmMax: undefined,
          paramType: 'SERVO-DELAY',
        }
        break;
      default:
        this.saLock.lockParam = {
          paramType: 'NONE',
        }
        break;
    }
    this.setupParamSets();
  }

  setupParamSets() {
    this.lockDefaultParamSetOptions = [];
    var lockType = this.saLock.type.startsWith("Servo") ? "Servo" : this.saLock.type;
    let lockTypeSets = this.lockDefaultParamSets[lockType];
    this.lockDefaultParamSetSelected = undefined;
    switch (lockType) {
      case 'Servo':
      case 'ServoDelayed':
        this.lockDefaultParamSetOptions.push({
          id: 'CUSTOM',
          name: 'Custom Servo',
          lockType: 'Servo',
          params: {
            angle: undefined,
            angleMin: undefined,
            angleMax: undefined,
            pwmMin: undefined,
            pwmMax: undefined
          }
        });
        for (const set of lockTypeSets) {
          this.lockDefaultParamSetOptions.push(set);
          if (!this.lockDefaultParamSetSelected
            && this.saLock.lockParam.angle === set.params['angle']
            && this.saLock.lockParam.angleMin === set.params['angleMin']
            && this.saLock.lockParam.angleMax === set.params['angleMax']
            && this.saLock.lockParam.pwmMin === set.params['pwmMin']
            && this.saLock.lockParam.pwmMax === set.params['pwmMax']) {
            this.lockDefaultParamSetSelected = set;
          }
        }
        if (!this.lockDefaultParamSetSelected && (
          this.saLock.lockParam.angle
          || this.saLock.lockParam.angleMin
          || this.saLock.lockParam.angleMax
          || this.saLock.lockParam.pwmMin
          || this.saLock.lockParam.pwmMax
        )) this.lockDefaultParamSetSelected = this.lockDefaultParamSetOptions[0];
        break;
    }
  }

  paramSetCompare(o, s) {
    if (o === undefined && s === undefined) return true;
    if (o === undefined || s === undefined) return false;
    return o.id === s.id;
  }

  selectParamSet(option: MatSelectChange) {
    if (option.value) {
      for (const paramsKey in option.value.params) {
        this.saLock.lockParam[paramsKey] = option.value.params[paramsKey];
      }
    }
  }

  csvToLines(csv: string) {
    if (csv) return csv.replace(/\s*,\s*/g, "\n");
    return "";
  }

  getFirmwareConfigObj(element: any) {
    if (element.firmwareConfig && !element.firmwareConfigObj) {
      try {
        element.firmwareConfigObj = JSON.parse(element.firmwareConfig);
      } catch (e) {
        element.firmwareConfigObj = {};
      }
    }
    return element.firmwareConfigObj;
  }

  calculateServoAngleMax(lock: any) {
    if (lock.deviceType === 'Taulock Micro') {
      return lock.lockParam.angleMin*1 + Math.floor((lock.lockParam.angleMax - lock.lockParam.angleMin) * (140/180));
    } else {
      return lock.lockParam.angleMax;
    }
  }
}
