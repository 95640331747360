<div *ngIf="saLock">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>lock</mat-icon> {{!saLock.id ? 'Create new Secure Access Lock' : ('Secure Access Lock ' + saLock.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!saLock.id ? 'add' : 'save'}}</mat-icon>
        {{(saLock.id ? 'Update ' : 'Add ')}}
      </button>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <div class="padded vbox-space">
        <div>
          <form #form="ngForm" role="form" class="vbox">
            <table class="basicTable vtable">
              <thead>
              <tr>
                <th colspan="100">Secure Access Lock Configuration</th>
              </tr>
              </thead>
              <tbody>

              <tr *ngIf="authService.hasRole('COMPANY_ADMIN')">
                <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
                <td>
                  <micro-company-select #companyId="ngModel"
                                        [(ngModel)]="saLock.companyId"
                                        [required]="true"
                                        [disabled]="!authService.hasRole('SITE_LOCK_COMPANY') || (saLock.id && saLock.cabinetId) || saLock.openAccessRequestCount > 0"
                                        id="companyId"
                                        name="companyId"
                  >

                  </micro-company-select>
                </td>
              </tr>
              <tr *ngIf="!authService.hasRole('COMPANY_ADMIN')">
                <th><micro-valid-label for="companyId2" [model]="companyId" label="Company"></micro-valid-label></th>
                <td>
                  <micro-saHostCompany-select #companyId="ngModel"
                                              [(ngModel)]="saLock.companyId"
                                              [required]="true"
                                              id="companyId2"
                                              name="companyId"
                                              [includeHosted]="true"
                                              [disabled]="!authService.hasRole('SITE_LOCK_COMPANY') || (saLock.id && saLock.cabinetId) || saLock.openAccessRequestCount > 0"
                  >
                  </micro-saHostCompany-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
                <td>
                  <input #name="ngModel"
                         [(ngModel)]="saLock.name"
                         required
                         placeholder="Name"
                         id="name"
                         name="name"
                         [disabled]="!authService.hasRole('SITE_LOCK_COMPANY') || saLock.openAccessRequestCount > 0"
                  >
                </td>
              </tr>
              <tr *ngIf="saLock.openAccessRequestCount > 0 ">
                <th [ngClass]="saLock.openAccessRequestCount > 0 ? 'CRITICAL' : ''">Open Access Requests</th>
                <td>
                  {{saLock.openAccessRequestCount}}
                </td>
              </tr>
              <tr>
                <th>Created</th>
                <td>
                  {{saLock.createdDate | dateTime}}
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="lockType" [model]="lockType" label="Type"></micro-valid-label></th>
                <td>
                  <micro-saLock-type-select #lockType="ngModel"
                                            [(ngModel)]="saLock.type"
                                            [required]="true"
                                            [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')"
                                            (saLockTypeChange)="typeChange()"
                                            id="lockType"
                                            name="lockType">
                  </micro-saLock-type-select>
                </td>
              </tr>
              <ng-container *ngIf="saLock.type == 'Servo' || saLock.type == 'ServoDelayed'">
                <tr>
                  <th colspan="99" style="text-align: center;">Servo Lock Parameters</th>
                </tr>
                <tr>
                  <th>Select Default Parameter Set</th>
                  <td>
                    <mat-select [(value)]="lockDefaultParamSetSelected"
                                [compareWith]="paramSetCompare"
                                (selectionChange)="selectParamSet($event)"
                                placeholder="Select set or enter values"
                                [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')">
                      <mat-option *ngFor="let option of lockDefaultParamSetOptions" [value]="option">{{option.name}}</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr>
                  <th><micro-valid-label for="servoAngleMin" [model]="servoAngleMin" label="Min Angle"></micro-valid-label></th>
                  <td>
                    <input #servoAngleMin="ngModel"
                           [(ngModel)]="saLock.lockParam.angleMin"
                           name="servoAngleMin"
                           id="servoAngleMin"
                           required
                           [microMax]="saLock.lockParam.angleMax"
                           placeholder="Minimum Servo angle"
                           [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')" />
                  </td>
                </tr>
                <tr>
                  <th><micro-valid-label for="servoAngleMax" [model]="servoAngleMax" label="Max Angle"></micro-valid-label></th>
                  <td>
                    <input #servoAngleMax="ngModel"
                           [(ngModel)]="saLock.lockParam.angleMax"
                           name="servoAngleMax"
                           id="servoAngleMax"
                           required
                           placeholder="Maximum Servo angle"
                           [microMin]="saLock.lockParam.angleMin"
                           [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')" />
                  </td>
                </tr>
                <tr>
                  <th><micro-valid-label for="servoAngle" [model]="servoAngle" label="Open Angle"></micro-valid-label><br/>
                    Between {{saLock.lockParam.angleMin}} and {{calculateServoAngleMax(saLock)}}
                  </th>
                  <td>
                    <input #servoAngle="ngModel"
                           [(ngModel)]="saLock.lockParam.angle"
                           name="servoAngle"
                           id="servoAngle"
                           required
                           [microMin] = "saLock.lockParam.angleMin"
                           [microMax] = "calculateServoAngleMax(saLock)"
                           placeholder="Servo angle to open"
                           [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')" />
                  </td>
                </tr>
                <tr>
                  <th><micro-valid-label for="servoPwmMin" [model]="servoPwmMin" label="Min Angle PWM"></micro-valid-label></th>
                  <td>
                    <input #servoPwmMin="ngModel"
                           [(ngModel)]="saLock.lockParam.pwmMin"
                           name="servoPwmMin"
                           id="servoPwmMin"
                           required
                           placeholder="Minimum Servo Angle PWM"
                           [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')" />
                  </td>
                </tr>
                <tr>
                  <th><micro-valid-label for="servoPwmMax" [model]="servoPwmMax" label="Max Angle PWM"></micro-valid-label></th>
                  <td>
                    <input #servoPwmMax="ngModel"
                           [(ngModel)]="saLock.lockParam.pwmMax"
                           name="servoPwmMax"
                           id="servoPwmMax"
                           required
                           placeholder="Maximum Servo Angle PWM"
                           [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')" />
                  </td>
                </tr>
              </ng-container>
              <tr>
                <th colspan="99" style="text-align: center;">Lock Config/Details</th>
              </tr>
              <tr>
                <th><micro-valid-label for="validityPeriod" [model]="validityPeriod" label="Validity Period (Hours)"></micro-valid-label></th>
                <td>
                  <select #validityPeriod="ngModel"
                          [(ngModel)]="saLock.validityPeriod"
                          name="validityPeriod"
                          id="validityPeriod"
                          required
                          [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')">
                    <option [value]="2">2</option>
                    <option [value]="4">4</option>
                    <option [value]="6">6</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="status" [model]="status" label="Status"></micro-valid-label></th>
                <td>
                  <micro-saLock-status-select #status="ngModel"
                                              [(ngModel)]="saLock.status"
                                              [required]="true"
                                              [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')"
                                              id="status"
                                              name="status"
                  >
                  </micro-saLock-status-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="serialNumber" [model]="serialNumber" label="Serial Number"></micro-valid-label></th>
                <td>
                  <input #serialNumber="ngModel"
                         [(ngModel)]="saLock.serialNumber"
                         [required]="!disableSerial || !saLock.id"
                         placeholder="Serial Number"
                         id="serialNumber"
                         name="serialNumber"
                         [disabled]="disableSerial || saLock.id">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="mac" [model]="mac" label="MAC Address"></micro-valid-label></th>
                <td><input #mac="ngModel"
                           [(ngModel)]="saLock.mac"
                           [required]="!saLock.id"
                           placeholder="MAC"
                           id="mac"
                           name="mac"
                           [disabled]="!authService.hasRole('SITE_LOCK_COMPANY') || saLock.id">
                </td>
              </tr>
              <tr>
                <th>Cabinet</th>
                <td>
                  <micro-saCabinet-select [companyId]="saLock.companyId"
                                          [(saCabinetId)]="saLock.cabinetId"
                                          [required]="false"
                                          allLabel="Not set"
                                          [disabled]="!authService.hasRole('SITE_LOCK_CABINET') || !authService.hasRole('SITE_LOCK_COMPANY') || saLock.openAccessRequestCount > 0">
                  </micro-saCabinet-select>
                </td>
              </tr>
              <tr>
                <th>Site</th>
                <td>
                  <micro-site-select [companyId]="saLock.companyId"
                                     [(siteId)]="saLock.siteId"
                                     [pickList]="false"
                                     [required]="false"
                                     allLabel="Not set"
                                     [disabled]="!authService.hasRole('SITE_LOCK_SITE') || saLock.cabinetId || !authService.hasRole('SITE_LOCK_COMPANY') || saLock.openAccessRequestCount > 0">
                  </micro-site-select>
                </td>
              </tr>
              <tr>
                <th colspan="99" style="text-align: center;">Firmware</th>
              </tr>
              <tr>
                <th>Device Type</th>
                <td><input [value]="saLock.deviceType"
                           placeholder="Taulock"
                           [disabled]="true">
                </td>
              </tr>
              <tr>
                <th>Git Branch</th>
                <td><input [value]="saLock.gitBranch"
                           placeholder="N/A"
                           [disabled]="true">
                </td>
              </tr>
              <tr>
                <th>Git Revision</th>
                <td><input [value]="saLock.gitRevision"
                           placeholder="N/A"
                           [disabled]="true">
                </td>
              </tr>
              <tr>
                <th>Profile</th>
                <td><input [value]="getFirmwareConfigObj(saLock)?.profile"
                           placeholder="N/A"
                           [disabled]="true">
                </td>
              </tr>
              <tr>
                <th>MSISDN</th>
                <td><input [value]="getFirmwareConfigObj(saLock)?.alarmMsisdn"
                           placeholder="N/A"
                           [disabled]="true">
                </td>
              </tr>
              <tr>
                <th>Auto Reboot (min)</th>
                <td><input [value]="getFirmwareConfigObj(saLock)?.autoRebootMinutes"
                           placeholder="0"
                           [disabled]="true">
                </td>
              </tr>
              <tr>
                <th>Ethernet Enabled</th>
                <td><input [value]="getFirmwareConfigObj(saLock)?.ethernet ? 'YES' : 'NO'"
                           placeholder="NO"
                           [disabled]="true">
                </td>
              </tr>
              <tr>
                <th>rfIds</th>
                <td>
                  <textarea style="min-height: 93px"
                            [disabled]="true"
                            [value]="getFirmwareConfigObj(saLock)?.rfIds"
                            cols="10"
                            placeholder="None"
                            rows="5">
                  </textarea>
                </td>
              </tr>
              <tr>
              <th>Raw Config</th>
                <td>
                  <textarea style="min-width: 350px; min-height: 220px;"
                            [disabled]="true"
                            [value]="getFirmwareConfigObj(saLock) | json:2"
                            cols="10"
                            placeholder="None"
                            rows="5">
                  </textarea>
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>

      <ng-container *ngIf="saLock.cabinetId">
        <micro-saCabinet-detail #saCabinetDetailComponent [saCabinetId]="saLock.cabinetId" [disabled]="true"></micro-saCabinet-detail>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="saLock.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="SaLock" [objectId]="saLock.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>
